//@ts-ignore
import type { Types } from '@cornerstonejs/core';
export type GSPSDisplaySet = {

    Modality: string;
    displaySetInstanceUID: string;
    SeriesDescription: string;
    SeriesNumber: string;
    SeriesDate: string | Date;
    SOPInstanceUID: string;
    SeriesInstanceUID: string;
    StudyInstanceUID: string;
    SOPClassHandlerId: string;
    SOPClassUID: string;
    instances: [],
    isDerivedDisplaySet: boolean;
    sopClassUids: string[];
    instance: any;
    addInstances: any;
    excludeFromThumbnailBrowser?: boolean;
};
export type GSPSDataType = {
    FrameOfReferenceUID: string;
    Data: any[];
};
export enum GSPSGraphicType {
    POINT = 'POINT',
    MULTIPOINT = 'MULTIPOINT',
    POLYLINE = 'POLYLINE',
    CIRCLE = 'CIRCLE',
    ELLIPSE = 'ELLIPSE',
};
export const DICOMSRDisplayTool = "DICOMSRDisplay";

export type GSPSRecordCoord = {
    ValueType: string,
    GraphicType: GSPSGraphicType;
    GraphicData: number[],
    ReferencedSOPSequence: any;
    ReferencedFrameOfReferenceSequence: any;
};
export type GSPSRecord = {
    GraphicType: any;
    imageId: string | undefined;
    ReferencedSOPInstanceUID: string | undefined;
    frameNumber: number | undefined;
    worldPos: [number[]] | undefined;
    GraphicAnnotationUnits: any;
    GraphicData: any;
    GraphicGroupID: any;
};
export type canvasCoordinates = [
    Types.Point2, // center
    Types.Point2 // end
];
