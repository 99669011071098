//@ts-nocheck
import {
  getEnabledElement,
  utilities,
  getEnabledElementByIds,
  getEnabledElements,
  metaData,
  triggerEvent,
  eventTarget,
} from '@cornerstonejs/core';
import type { Types } from '@cornerstonejs/core';

import { classes } from '@ohif/core';
import {
  ReferenceCursors,
  ToolGroupManager,
  utilities as cUtils,
  cursors,
  annotation,
  drawing,
  Types as cTypes,
} from '@cornerstonejs/tools';
import { GSPSRecordReferencesSOPInstanceUID, processGSPSRecord } from '../utils/loadGSPSData';
import GSPSImageMetaDataProvider from '../utils/GSPSImageMetaDataProvider';
import DBTGraphicsMetaDataProvider from '../utils/DBTGraphicsMetaDataProvider';
const { transformWorldToIndex } = utilities;
//@ts-ignore
const { MetadataProvider: metadataProvider } = classes;
const { getToolGroup } = ToolGroupManager;
const { isAnnotationVisible } = annotation.visibility;

const { addAnnotation, getAnnotations, removeAnnotation } = annotation.state;
const { triggerAnnotationRenderForViewportIds, viewportFilters } = cUtils;
const { setCursorForElement } = cursors;
const { resetElementCursor, hideElementCursor } = cursors.elementCursor;
const { getViewportIdsWithToolToRender } = viewportFilters;
const changeSliceEvent = 'VIEWPORT_SLICE_CHANGED';
const { drawEllipseByCoordinates } = drawing;
const { EventTypes, ToolHandle, PublicToolProps, ToolProps, SVGDrawingHelper } = cTypes;
export type StyleSpecifier = {
  viewportId?: string;
  toolGroupId?: string;
  toolName?: string;
  annotationUID?: string;
};
type WorldPoint = [number, number, number];
const MLO_VIEW = 'MLO';
class GSPSMapperTool extends ReferenceCursors {
  static toolName;
  touchDragCallback: any;
  mouseDragCallback: any;
  _throttledCalculateCachedStats: any;
  isDrawing = false;
  isHandleOutsideImage = false;
  _elementWithCursor: null | HTMLDivElement = null;
  _currentCursorWorldPosition: null | Types.Point3 = null;
  _currentCanvasPosition: null | Types.Point2 = null;
  activeViewportId: string | undefined;
  _disableCursorEnabled = false;
  imageId: string | undefined;
  viewport: any;
  sourcePoints: [];
  renderingEngine: any;
  activeTargetViewportID: any;
  constructor(
    toolProps: PublicToolProps = {},
    defaultToolProps: ToolProps = {
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        shadow: true,
        preventHandleOutsideImage: false,
        displayThreshold: 5,
        positionSync: true,
        disableCursor: false,
      },
    }
  ) {
    super(toolProps, defaultToolProps);
    this._disableCursorEnabled = this.configuration.disableCursor;
  }

  mouseMoveCallback = (evt: EventTypes.InteractionEventType): boolean => {
    const { detail } = evt;
    const { element, currentPoints, viewportId } = detail;
    if (viewportId && !this.activeViewportId) {
      this.activeViewportId = viewportId;
    }
    const isPointInsideMask = this.currentPointsInsideMaskArea(element, currentPoints.world);
    if (!isPointInsideMask) {
      setCursorForElement(element, 'not-allowed');
      const annotations = getAnnotations(this.getToolName(), element);
      if (annotations.length == 0) {
        return;
      }
      annotations.forEach(annotation => {
        removeAnnotation(annotation.annotationUID);
      });
      const targetViewportToRender = this.getTargetViewportToRender();
      if (targetViewportToRender) {
        this.activeTargetViewportID = targetViewportToRender.viewportId;
        triggerAnnotationRenderForViewportIds(this.renderingEngine, [
          targetViewportToRender.viewportId,
        ]);
      }
      return;
    } else {
      setCursorForElement(element, 'default');
    }
    this._currentCursorWorldPosition = currentPoints.world;
    this._currentCanvasPosition = currentPoints.canvas;
    this._elementWithCursor = element;
    const annotation = this.getActiveAnnotation(element);
    if (annotation === null) {
      this.createInitialAnnotation(currentPoints.world, element);
      return false;
    }
    this.updateAnnotationPosition(element, annotation);
    return false;
  };

  getTargetEllipseBySourceGraphicGroupID(imageId) {
    if (!imageId) {
      return;
    }
    const { SeriesInstanceUID } = metaData.get('instance', imageId);
    if (!SeriesInstanceUID) {
      return;
    }
    const seriesGSPSRecords = DBTGraphicsMetaDataProvider.get(
      'displaysetImagesProvider',
      SeriesInstanceUID
    );
    if (!seriesGSPSRecords || !seriesGSPSRecords.GSPSRecords.target) {
      return;
    }

    const targetEllipse = seriesGSPSRecords.GSPSRecords.target.filter(
      graph => graph.GraphicGroupID === this.graphicGroupID
    );
    if (targetEllipse && targetEllipse.length === 1) {
      return {
        targetEllipseGraphicData: targetEllipse[0].GraphicData,
        slice: targetEllipse[0].frameNumber,
      };
    }
  }
  getTargetViewportToRender() {
    const enabledElements = getEnabledElements();
    if (!enabledElements) {
      return;
    }
    const { viewport: activeViewport } = enabledElements.filter(
      element => element.viewport.id === this.activeViewportId
    )[0];
    if (!activeViewport) {
      return;
    }
    const { options } = activeViewport;
    if (!options || !options._viewPos) {
      return;
    }
    const currentViewPos = options._viewPos;
    const currentLetiraly = options._letiraly;
    return enabledElements.filter(
      element =>
        element.viewport.options &&
        element.viewport.options._viewPos &&
        element.viewport.options._viewPos !== currentViewPos &&
        element.viewport.options._letiraly === currentLetiraly
    )[0];
  }
  _calculateLength(pos1, pos2) {
    const dx = pos1[0] - pos2[0];
    const dy = pos1[1] - pos2[1];
    const dz = pos1[2] - pos2[2];

    return Math.sqrt(dx * dx + dy * dy + dz * dz);
  }

  _findNearestNeighbor(point: WorldPoint, points: any[]) {
    let minDistance = Infinity;
    let nearestNeighborIndexRow = 0;

    for (let i = 0; i < points.length; i++) {
      const otherPoint = points[i].worldPos[0];
      if (point === otherPoint) {
        nearestNeighborIndexRow = points[i].GraphicGroupID;
        break;
      }
      const distance = this._calculateLength(point, otherPoint);
      if (distance < minDistance) {
        minDistance = distance;
        nearestNeighborIndexRow = points[i].GraphicGroupID;
      }
    }
    return nearestNeighborIndexRow;
  }
  findNearestNeighborPoints(point, imageId, GSPSImage) {
    if (!GSPSImage || !GSPSImage.GSPSRecords || !GSPSImage.GSPSRecords.source) {
      return;
    }

    const imageSourcePoints = GSPSImage.GSPSRecords.source.sort((a, b) => {
      return a.index - b.index;
    });
    const result = this._findNearestNeighbor(point, imageSourcePoints);
    this.graphicGroupID = result;
    return result;
  }

  onSetToolActive(): void {
    this._disableCursorEnabled = this.configuration.disableCursor;
    if (!this._disableCursorEnabled) {
      return;
    }
    const viewportIds = getToolGroup(this.toolGroupId).viewportsInfo;
    if (!viewportIds) {
      return;
    }
    const enabledElements = viewportIds.map(e =>
      getEnabledElementByIds(e.viewportId, e.renderingEngineId)
    );

    enabledElements.forEach(element => {
      if (element) {
        hideElementCursor(element.viewport.element);
      }
    });
  }
  onSetToolDisabled(): void {
    if (!this._disableCursorEnabled) {
      return;
    }
    const viewportIds = getToolGroup(this.toolGroupId).viewportsInfo;
    if (!viewportIds) {
      return;
    }
    const enabledElements = viewportIds.map(e =>
      getEnabledElementByIds(e.viewportId, e.renderingEngineId)
    );
    enabledElements.forEach(element => {
      if (element) {
        resetElementCursor(element.viewport.element);
      }
    });
  }

  createInitialAnnotation = (worldPos: Types.Point3, element: HTMLDivElement): void => {
    const enabledElement = getEnabledElement(element);
    if (!enabledElement) {
      throw new Error('No enabled element found');
    }
    const { viewport, renderingEngine } = enabledElement;

    this.isDrawing = true;
    this.renderingEngine = renderingEngine;
    const camera = viewport.getCamera();
    const { viewPlaneNormal, viewUp } = camera;
    if (!viewPlaneNormal || !viewUp) {
      throw new Error('Camera not found');
    }

    const referencedImageId = this.getReferencedImageId(
      viewport,
      worldPos,
      viewPlaneNormal,
      viewUp
    );
    this.activeViewportId = viewport.id;
    const FrameOfReferenceUID = viewport.getFrameOfReferenceUID();

    const annotation = {
      highlighted: true,
      invalidated: true,
      metadata: {
        toolName: this.getToolName(),
        viewPlaneNormal: [...viewPlaneNormal] as Types.Point3,
        viewUp: [...viewUp] as Types.Point3,
        FrameOfReferenceUID,
        referencedImageId,
      },
      data: {
        label: '',
        handles: {
          points: [[...worldPos]] as [Types.Point3],
          activeHandleIndex: null,
          textBox: {
            hasMoved: false,
            worldPosition: [0, 0, 0] as Types.Point3,
            worldBoundingBox: {
              topLeft: [0, 0, 0] as Types.Point3,
              topRight: [0, 0, 0] as Types.Point3,
              bottomLeft: [0, 0, 0] as Types.Point3,
              bottomRight: [0, 0, 0] as Types.Point3,
            },
          },
        },
      },
    };

    const annotations = getAnnotations(this.getToolName(), element);

    if (annotations.length > 0) {
      return null;
    }
    const annotationId = addAnnotation(annotation, element);

    if (annotationId === null) {
      return;
    }

    const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName(), false);
    const targetViewportToRender = this.getTargetViewportToRender();
    if (targetViewportToRender) {
      const targetViewportReferencedImageId = this.getReferencedImageId(
        targetViewportToRender.viewport,
        worldPos,
        viewPlaneNormal,
        viewUp
      );
      const targetViewportCamera = targetViewportToRender.viewport.getCamera();
      const newAnnotation = {
        ...annotation,
        metadata: {
          toolName: this.getToolName(),
          viewPlaneNormal: [...targetViewportCamera.viewPlaneNormal] as Types.Point3,
          viewUp: [...targetViewportCamera.viewUp] as Types.Point3,
          FrameOfReferenceUID: targetViewportToRender.FrameOfReferenceUID,
          referencedImageId: targetViewportReferencedImageId,
        },
      };
      addAnnotation(newAnnotation, targetViewportToRender.viewport.element);
      viewportIdsToRender.push(targetViewportToRender.viewport.id);
      this.activeTargetViewportID = targetViewportToRender.viewportId;
    }
    try {
      this.renderSourceDataForImage(referencedImageId);
    } catch (error) {}
    triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);
  };

  getActiveAnnotation(element: HTMLDivElement): null | Annotation {
    const annotations = getAnnotations(this.getToolName(), element);
    if (!annotations.length) {
      return null;
    }
    const targetAnnotation = annotations[0];
    return targetAnnotation;
  }

  updateAnnotationPosition(element: HTMLDivElement, annotation: Annotation): void {
    const worldPos = this._currentCursorWorldPosition;
    if (!worldPos) {
      return;
    }
    if (
      !annotation ||
      !annotation.data ||
      !annotation.data.handles ||
      !annotation.data.handles.points
    ) {
      return;
    }
    annotation.data.handles.points = [[...worldPos]];
    annotation.invalidated = true;

    const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName(), false);
    const enabledElement = getEnabledElement(element);
    if (!enabledElement) {
      return;
    }
    const { renderingEngine, viewport } = enabledElement;
    this.activeViewportId = viewport.id;
    const imageId = viewport.getCurrentImageId();
    const targetViewport = this.getTargetViewportToRender();

    const sourceImageData = this.renderSourceDataForImage(imageId);
    if (sourceImageData) {
      this.findNearestNeighborPoints(annotation.data.handles.points[0], imageId, sourceImageData);
      if (targetViewport && targetViewport.viewport) {
        viewportIdsToRender.push(targetViewport.viewport.id);
      }
      this.activeTargetViewportID = targetViewport.viewport.id;
    }
    triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);
  }

  convertEllipseCoordToWorld(GraphicData, imageId) {
    const EllipseCoordsWorld: Types.Point3[] = [];
    for (let i = 0; i < GraphicData.length; i += 2) {
      const worldPos = utilities.imageToWorldCoords(imageId, [GraphicData[i], GraphicData[i + 1]]);
      EllipseCoordsWorld.push(worldPos);
    }

    return EllipseCoordsWorld;
  }
  renderSourceDataForImage(imageId) {
    if (!imageId) {
      return;
    }
    const GSPS3DImagePoints = GSPSImageMetaDataProvider.get('GSPS3DImagePoints', imageId);
    if (GSPS3DImagePoints) {
      return GSPS3DImagePoints;
    }
    const { SeriesInstanceUID } = metaData.get('instance', imageId);
    if (!SeriesInstanceUID) {
      return;
    }
    const seriesGSPSRecords = DBTGraphicsMetaDataProvider.get(
      'displaysetImagesProvider',
      SeriesInstanceUID
    );
    if (!seriesGSPSRecords || !seriesGSPSRecords.GSPSRecords) {
      return;
    }
    const { SOPInstanceUID, frameNumber } = metadataProvider.getUIDsFromImageID(imageId);
    const sourcceImageGSPSRecords = seriesGSPSRecords.GSPSRecords.source.filter(record =>
      GSPSRecordReferencesSOPInstanceUID(record, SOPInstanceUID, frameNumber)
    );
    for (let j = 0; j < sourcceImageGSPSRecords.length; j++) {
      processGSPSRecord(sourcceImageGSPSRecords[j], imageId);
    }
    GSPSImageMetaDataProvider.add(imageId, {
      GSPSRecords: {
        source: sourcceImageGSPSRecords,
      },
    });
    return GSPSImageMetaDataProvider.get('GSPS3DImagePoints', imageId);
  }
  currentPointsInsideMaskArea(element, worldPoint) {
    if (!element || !worldPoint) {
      return true;
    }
    const enabledElement = getEnabledElement(element);
    if (!enabledElement) {
      return true;
    }
    const { viewport } = enabledElement;
    const _imageData = viewport.getImageData();
    if (!_imageData) {
      return true;
    }
    const { imageData } = _imageData;
    const imageId = viewport.getCurrentImageId();
    if (!imageId) {
      return true;
    }
    const { SeriesInstanceUID } = metaData.get('instance', imageId);
    if (!SeriesInstanceUID) {
      return true;
    }
    const proccessedGSPSMaskMatrix = DBTGraphicsMetaDataProvider.get(
      'displaysetImagesProvider',
      SeriesInstanceUID
    );
    if (!proccessedGSPSMaskMatrix || !proccessedGSPSMaskMatrix.GSPSMaskMatrix) {
      return true;
    }
    const pointIndex = transformWorldToIndex(imageData, worldPoint);
    const maskUnit16Array = proccessedGSPSMaskMatrix.GSPSMaskMatrix;
    if (maskUnit16Array.length === 0) {
      return true;
    }
    const maskX = pointIndex[0];
    const maskY = pointIndex[1];
    if (
      maskX < 0 ||
      maskX > maskUnit16Array[0].length ||
      maskY < 0 ||
      maskY > maskUnit16Array.length
    ) {
      return;
    }
    const viewPos = viewport.options._viewPos;
    const isInsideMask =
      maskUnit16Array[maskY][maskX] === 1 ||
      (maskUnit16Array[maskY][maskX] === 2 && viewPos === MLO_VIEW);
    return isInsideMask;
  }

  renderEllipse(svgDrawingHelper, viewport, data, annotationUID, referencedImageId, options) {
    let canvasCoordinates;
    if (!data || data.length === 0) {
      return;
    }

    const ellipsePointsWorld = this.convertEllipseCoordToWorld(data, referencedImageId);
    canvasCoordinates = ellipsePointsWorld.map(p => viewport.worldToCanvas(p));

    const dataId = `${annotationUID}-ellipse`;
    const ellipseUID = '0';
    drawEllipseByCoordinates(
      svgDrawingHelper,
      annotationUID,
      ellipseUID,
      canvasCoordinates,
      {
        color: options.color,
        width: options.lineWidth,
      },
      dataId
    );

    return canvasCoordinates;
  }
  renderAnnotation = (
    enabledElement: Types.IEnabledElement,
    svgDrawingHelper: SVGDrawingHelper
  ): boolean => {
    let renderStatus = false;
    const { viewport, FrameOfReferenceUID } = enabledElement;

    const isElementWithCursor = this._elementWithCursor === viewport.element;

    const { element } = viewport;

    const annotations = getAnnotations(this.getToolName(), element);
    if (!annotations?.length) {
      return renderStatus;
    }

    const styleSpecifier: StyleSpecifier = {
      toolGroupId: this.toolGroupId,
      toolName: this.getToolName(),
      viewportId: enabledElement.viewport.id,
    };

    for (let i = 0; i < annotations.length; i++) {
      const annotation = annotations[i] as cTypes.ToolSpecificAnnotationTypes.ReferenceCursor;
      const { annotationUID, data } = annotation;
      const { handles } = data;
      const { points } = handles;

      if (!annotationUID) {
        return renderStatus;
      }
      styleSpecifier.annotationUID = annotationUID;

      const lineWidthBase = parseFloat(
        this.getStyle('lineWidth', styleSpecifier, annotation) as string
      );

      const lineWidth =
        typeof lineWidthBase === 'number' && isElementWithCursor ? lineWidthBase : lineWidthBase;
      const lineDash = this.getStyle('lineDash', styleSpecifier, annotation);
      const color = this.getStyle('color', styleSpecifier, annotation);

      if (points[0].some(e => isNaN(e))) {
        return renderStatus;
      }

      if (!viewport.getRenderingEngine()) {
        console.warn('Rendering Engine has been destroyed');
        return renderStatus;
      }

      if (!isAnnotationVisible(annotationUID)) {
        continue;
      }
      if (!isElementWithCursor && viewport.id === this.activeTargetViewportID) {
        const targetEllipse = this.getTargetEllipseBySourceGraphicGroupID(
          viewport.getCurrentImageId()
        );
        if (!targetEllipse) {
          return;
        }

        const options = {
          color,
          lineDash,
          lineWidth,
        };
        const viewportActiveSlice = viewport.getCurrentImageIdIndex() + 1;
        if (
          targetEllipse.slice &&
          viewportActiveSlice &&
          targetEllipse.slice !== viewportActiveSlice
        ) {
          const targetSlice = targetEllipse.slice > 0 ? targetEllipse.slice - 1 : 0;
          const eventType = changeSliceEvent;
          const EventDetail = {
            targetSlice,
            viewport,
          };
          triggerEvent(eventTarget, eventType, EventDetail);
        }
        try {
          this.resetUnActiveViewportWithAnnotations();
        } catch (error) {}
        if (!targetEllipse.targetEllipseGraphicData) {
          return;
        }
        this.renderEllipse(
          svgDrawingHelper,
          viewport,
          targetEllipse.targetEllipseGraphicData,
          annotationUID,
          viewport.getCurrentImageId(),
          options
        );
      }
      renderStatus = true;
    }

    return renderStatus;
  };
  resetUnActiveViewportWithAnnotations() {
    const enabledElements = getEnabledElements();
    if (!enabledElements) {
      return;
    }
    const unActiveEnabeldElements = enabledElements.filter(
      element =>
        element.viewport.id !== this.activeViewportId &&
        element.viewport.id !== this.activeTargetViewportID
    );
    if (unActiveEnabeldElements.length === 0) {
      return;
    }
    unActiveEnabeldElements.forEach(enabledElement => {
      const { viewport, viewportId } = enabledElement;
      if (!viewport || !viewport.element) {
        return;
      }
      const annotations = getAnnotations(this.getToolName(), viewport.element);
      if (!annotations.length) {
        return;
      }
      triggerAnnotationRenderForViewportIds(this.renderingEngine, [viewportId]);
    });
  }
}

GSPSMapperTool.toolName = 'GSPSMapper';
export default GSPSMapperTool;
