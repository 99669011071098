//@ts-nocheck
import { addTool } from '@cornerstonejs/tools';
import * as cornerstone from '@cornerstonejs/core';
import getViewportModule from './getViewportModule';
import getHangingProtocolModule from './getHangingProtocolModule';
import getSopClassHandlerModule from './getSopClassHandlerModule';
import getLayoutTemplateModule from './getLayoutTemplateModule.js';
import { id } from './id.js';
import GSPSMapperTool from './tools/GSPSMapperTool';
import customizations from './utils/customization';
import GSPSService from './services/GSPSService';
import { maxCacheSize } from './Enums';
import Header from './ui/Header';
const mvpExtension = {
  id,
  getHangingProtocolModule,
  getPanelModule: ({
    commandsManager,
    extensionManager,
    servicesManager,
  }: {
    commandsManager: any;
    extensionManager: any;
    servicesManager: any;
  }) => {},
  getViewportModule,
  getSopClassHandlerModule,
  getLayoutTemplateModule,
  preRegistration({ servicesManager, commandsManager, extensionManager }) {
    if (maxCacheSize) {
      cornerstone.cache.setMaxCacheSize(maxCacheSize); // COMMENT MH: should we increase this cache size?
    }

    servicesManager.registerService(GSPSService.REGISTRATION);
    addTool(GSPSMapperTool);
    const { HangingProtocolService, customizationService } = servicesManager.services;
    const { id, items } = customizations.cornerstoneOverlayTopLeft;
    customizationService.setGlobalCustomization(id, items);
    HangingProtocolService.addCustomAttribute('ViewCodeSequence', 'ViewCodeSequence', metaData => {
      const viewCodeSeq =
        metaData['ViewCodeSequence'] ??
        ((metaData.images || metaData.others || [])[0] || {})['ViewCodeSequence'];
      return viewCodeSeq && viewCodeSeq.length > 0 ? viewCodeSeq[0].CodeValue : '';
    });
    HangingProtocolService.addCustomAttribute('FrameLaterality', 'FrameLaterality', metaData => {
      const FrameLaterality = metaData['FrameLaterality'];
      return FrameLaterality ? FrameLaterality : '';
    });
    HangingProtocolService.addCustomAttribute('ImageLaterality', 'ImageLaterality', metaData => {
      const FrameLaterality = metaData['ImageLaterality'];
      return FrameLaterality ? FrameLaterality : '';
    });
    HangingProtocolService.addCustomAttribute('ViewPosition', 'ViewPosition', metaData => {
      const FrameLaterality = metaData['ViewPosition'];
      return FrameLaterality ? FrameLaterality : '';
    });
    HangingProtocolService.addCustomAttribute('_viewPos', '_viewPos', metaData => {
      const FrameLaterality = metaData['_viewPos'];
      return FrameLaterality ? FrameLaterality : '';
    });
    HangingProtocolService.addCustomAttribute('_letiraly', '_letiraly', metaData => {
      const FrameLaterality = metaData['_letiraly'];
      return FrameLaterality ? FrameLaterality : '';
    });
  },
};

export default mvpExtension;
export { Header };
