import { GSPSRecord } from '../Enums/displaySet';
interface DBTGSPS {
    GSPSRecords: {
        target: GSPSRecord[],
        source: GSPSRecord[],
    },
    GSPSMaskMatrix: any,
};

const state: Record<string, DBTGSPS> = {};

const metadataProvider = {
    add: (SeriesInstanceUID: string, payload: DBTGSPS): void => {
        state[SeriesInstanceUID] = payload;
    },
    get: (type: string, SeriesInstanceUID: string): DBTGSPS | undefined => {
        if (type === 'displaysetImagesProvider') {
            return state[SeriesInstanceUID];
        }
    },
    remove: (type: string, SeriesInstanceUID: string) => {
        if (type === 'displaysetImagesProvider') {
            delete state[SeriesInstanceUID];
        }
    },
    destroy: () => {
        for (const key in state) {
            if (Object.hasOwnProperty.call(state, key)) {
                delete state[key];
            }
        }
    },
};
export default metadataProvider;
