// rotate.ts

// Rotate a point around a center
// function rotatePoint(
//   px: number,
//   py: number,
//   x: number,
//   y: number,
//   alpha: number
// ): [number, number] {
//   const alphaRad = degToRad(alpha); // Convert to radians
//   const pxNew = x + (px - x) * Math.cos(alphaRad) - (py - y) * Math.sin(alphaRad);
//   const pyNew = y + (px - x) * Math.sin(alphaRad) + (py - y) * Math.cos(alphaRad);
//   return [pxNew, pyNew];
// }

// Helper function to convert degrees to radians
function degToRad(angle: number): number {
  return (angle * Math.PI) / 180;
}
// Calculate the four corner points of a rotated rectangle
export default function fourPointsFormat(
  cx: number,
  cy: number,
  cwidth: number,
  cheight: number,
  angle: number
): [number, number, number, number, number, number, number, number] {
  const rotationAngleRad = degToRad(angle);

  // Points on the major axis
  const x1 = cx + (cwidth / 2) * Math.cos(rotationAngleRad);
  const y1 = cy + (cwidth / 2) * Math.sin(rotationAngleRad);
  const x2 = cx - (cwidth / 2) * Math.cos(rotationAngleRad);
  const y2 = cy - (cwidth / 2) * Math.sin(rotationAngleRad);

  // Points on the minor axis
  const x3 = cx + (cheight / 2) * Math.sin(rotationAngleRad);
  const y3 = cy - (cheight / 2) * Math.cos(rotationAngleRad);
  const x4 = cx - (cheight / 2) * Math.sin(rotationAngleRad);
  const y4 = cy + (cheight / 2) * Math.cos(rotationAngleRad);

  return [x1, y1, x2, y2, x3, y3, x4, y4];
}
