import { SOPClassHandlerName, SOPClassHandlerId } from './id';
//@ts-ignore
import { utils, classes, DisplaySetService, Types } from '@ohif/core';
import loadGSPSData from './utils/loadGSPSData';
import { GSPSDisplaySet } from './Enums/displaySet';
type InstanceMetadata = Types.InstanceMetadata;

const sopClassUids = ['1.2.840.10008.5.1.4.1.1.11.1'];

const validateSameStudyUID = (uid: string, instances): void => {
  instances.forEach(it => {
    if (it.StudyInstanceUID !== uid) {
      console.warn('Not all instances have the same UID', uid, it);
      throw new Error(`Instances ${it.SOPInstanceUID} does not belong to ${uid}`);
    }
  });
};

function addInstances(instances: InstanceMetadata[], displaySetService: DisplaySetService) {
  this.instances.push(...instances);
  utils.sortStudyInstances(this.instances);
  this.instance = this.instances[this.instances.length - 1];
  this.isLoaded = false;
  return this;
}
function _getDisplaySetsFromSeries(instances, servicesManager, extensionManager) {
  if (!instances || !instances.length) {
    throw new Error('No instances were provided');
  }

  utils.sortStudyInstances(instances);
  const instance = instances[instances.length - 1];

  const {
    StudyInstanceUID,
    SeriesInstanceUID,
    SOPInstanceUID,
    SeriesDescription,
    SeriesNumber,
    SeriesDate,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    GraphicAnnotationSequence,
    SOPClassUID,
  } = instance;
  validateSameStudyUID(instance.StudyInstanceUID, instances);

  // if (!GraphicAnnotationSequence) {
  //   return [];
  // }

  const displaySet: GSPSDisplaySet = {
    Modality: 'GSPS',
    displaySetInstanceUID: utils.guid(),
    SeriesDescription,
    SeriesNumber,
    SeriesDate,
    SOPInstanceUID,
    SeriesInstanceUID,
    StudyInstanceUID,
    SOPClassHandlerId,
    SOPClassUID,
    instances,
    isDerivedDisplaySet: true,
    sopClassUids,
    instance,
    addInstances,
    excludeFromThumbnailBrowser: true,
  };
  loadGSPSData(extensionManager, displaySet);
  return [displaySet];
}

function getSopClassHandlerModule({ servicesManager, extensionManager }) {
  const getDisplaySetsFromSeries = instances => {
    return _getDisplaySetsFromSeries(instances, servicesManager, extensionManager);
  };

  return [
    {
      name: SOPClassHandlerName,
      sopClassUids,
      getDisplaySetsFromSeries,
    },
  ];
}

export default getSopClassHandlerModule;
