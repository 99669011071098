const customizations = {
    cornerstoneOverlayTopLeft: {
        id: 'cornerstoneOverlayTopLeft',
        items: [
            {
                id: 'PatientName',
                customizationType: 'ohif.overlayItem',
                label: 'Patient: ',
                color: 'rgb(123 178 206 /1)',
                background: 'white',
                condition: ({ instance }) =>
                    instance && instance.PatientName && instance.PatientName.Alphabetic,
                contentF: ({ instance, formatters: { formatPN } }) =>
                    formatPN(instance.PatientName.Alphabetic)

            },
            {
                id: 'PID',
                customizationType: 'ohif.overlayItem',
                label: 'Patient ID:',
                color: 'rgb(123 178 206 /1)',
                title: 'Patient PID',
                condition: ({ instance }) => instance && instance.PatientID,
                contentF: ({ instance }) => instance.PatientID,
            },

            {
                id: 'PatientBirthDate',
                customizationType: 'ohif.overlayItem',
                label: 'Birth:',
                color: 'rgb(123 178 206 /1)',
                title: "Patient's Date of birth",
                condition: ({ instance }) => instance && instance.PatientBirthDate,
                contentF: ({ instance }) => instance.PatientBirthDate,
            },
            {
                id: 'PatientAge',
                customizationType: 'ohif.overlayItem',
                label: 'Age:',
                color: 'rgb(123 178 206 /1)',
                title: "Patient's Age",
                condition: ({ instance }) => instance && instance.PatientAge,
                contentF: ({ instance }) => instance.PatientAge,
            },
            {
                id: 'AcquisitionDate',
                customizationType: 'ohif.overlayItem',
                label: 'Acquisition:',
                color: 'rgb(123 178 206 /1)',
                title: "Acquisition Date",
                condition: ({ instance }) => instance && instance.AcquisitionDate,
                contentF: ({ instance }) => instance.AcquisitionDate,
            },
            {
                id: 'OperatorsName',
                customizationType: 'ohif.overlayItem',
                label: 'Operator:',
                color: 'rgb(123 178 206 /1)',
                title: "Operators Name",
                condition: ({ instance }) => instance && instance.OperatorsName && instance.OperatorsName.Alphabetic,
                contentF: ({ instance }) => instance.OperatorsName.Alphabetic,
            },

            {
                id: 'InstitutionName',
                customizationType: 'ohif.overlayItem',
                label: 'Institution:',
                color: 'rgb(123 178 206 /1)',
                title: "Institution Name",
                condition: ({ instance }) => instance && instance.InstitutionName,
                contentF: ({ instance }) => instance.InstitutionName,
            },
            {
                id: 'StationName',
                customizationType: 'ohif.overlayItem',
                label: 'Station:',
                color: 'rgb(123 178 206 /1)',
                fontSize: '12px',
                title: "Station Name",
                condition: ({ instance }) => instance && instance.StationName,
                contentF: ({ instance }) => instance.StationName,
            },
            {
                id: 'ManufacturerModelName',
                customizationType: 'ohif.overlayItem',
                label: 'Scanner:',
                color: 'rgb(123 178 206 /1)',
                fontSize: '12px',
                title: "Scanner",
                condition: ({ instance }) => instance && instance.ManufacturerModelName,
                contentF: ({ instance }) => instance.ManufacturerModelName,
            },
            {
                id: 'Spacing',
                customizationType: 'ohif.overlayItem',
                label: 'PixelSpacing:',
                color: 'rgb(123 178 206 /1)',
                fontSize: '12px',
                title: "PixelSpacing",
                condition: ({ instance }) => instance && instance.PixelSpacing,
                contentF: ({ instance }) => instance.PixelSpacing,
            },
            {
                id: 'Thickness',
                customizationType: 'ohif.overlayItem',
                label: 'SliceThickness:',
                color: 'rgb(123 178 206 /1)',
                fontSize: '12px',
                title: "SliceThickness",
                condition: ({ instance }) => instance && instance.SliceThickness,
                contentF: ({ instance }) => instance.SliceThickness,
            },

        ],
    }
};
export default customizations;
