import fourPointsFormat from './rotate';
import { GSPSGraphicType, GSPSRecord } from '../Enums/displaySet';
import { SlabMode } from '@kitware/vtk.js/Imaging/Core/ImageReslice/Constants';

async function fetchMultipartData(url) {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      console.log(`Failed to fetch data. Status code: ${response.status}`);
      return;
    }

    // Retrieve the Content-Type header to get the boundary
    const contentType = response.headers.get('Content-Type');
    const boundary = `--${contentType.split('boundary=')[1]}`;
    const boundaryBuffer = Buffer.from(boundary);

    // Read the response as an ArrayBuffer and convert it to a Buffer
    const responseArrayBuffer = await response.arrayBuffer();
    const responseBuffer = Buffer.from(responseArrayBuffer);

    let start = 0;
    let end = 0;

    while (end < responseBuffer.length) {
      const boundaryIndex = responseBuffer.indexOf(boundaryBuffer, start);
      if (boundaryIndex === -1) {
        break;
      }

      start = boundaryIndex + boundaryBuffer.length;
      const nextBoundaryIndex = responseBuffer.indexOf(boundaryBuffer, start);
      end = nextBoundaryIndex !== -1 ? nextBoundaryIndex : responseBuffer.length;

      const part = responseBuffer.slice(start, end);

      if (part.includes(Buffer.from('Content-Type: application/octet-stream'))) {
        const headerEndIndex = part.indexOf('\r\n\r\n') + 4;
        const bodyContent = part.slice(headerEndIndex); // Skip to the payload
        return bodyContent;
      }
      start = end;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

function reshapeMatrixFromDicomWeb(buffer, shape) {
  const reshapedArray = [];
  const dataView = new DataView(buffer.buffer, buffer.byteOffset, buffer.byteLength);

  for (let i = 0; i < shape[0]; i++) {
    const row = [];
    for (let j = 0; j < shape[1]; j++) {
      row.push(dataView.getUint16((i * shape[1] + j) * 2, true)); // Read 16-bit as little-endian
    }
    reshapedArray.push(row);
  }
  return reshapedArray;
}

function reshapeMatrixFromLocalFile(maskMatrix_bytes, maskShape) {
  const maskUnit16 = new Uint16Array(maskMatrix_bytes);
  const reshapedArray: any = [];
  for (let i = 0; i < maskShape[0]; i++) {
    reshapedArray.push(maskUnit16.slice(i * maskShape[1], i * maskShape[1] + maskShape[1]));
  }
  return reshapedArray;
}

function getBulkUrl(extensionManager, url) {
  const regex = /studies\/(.+)/;
  const match = url.match(regex);
  const activeDataSource = extensionManager.getActiveDataSource()[0];
  if (!activeDataSource) {
    return url;
  }
  const qidoRoot = activeDataSource.getConfig().qidoRoot;
  return `${qidoRoot}/${match ? match[0] : ''}`;
}
export default async function processBreastMaskMatrixAndMappingMatrix(
  extensionManager,
  GSPSInstance
) {
  // get ReferencedSOPInstanceUID
  const referencedSOPInstanceUID =
    GSPSInstance.ReferencedSeriesSequence[0].ReferencedImageSequence[0].ReferencedSOPInstanceUID;

  // check if all necessary private header fields are present 15,17,25
  if (!GSPSInstance['00091011']) {
    return;
  }
  if (!GSPSInstance['00091013']) {
    return;
  }
  // these are for the mapping matrix and corresponding mapping matrix shape
  if (!GSPSInstance['00091015']) {
    // mapping matrix
    return;
  }
  if (!GSPSInstance['00091017']) {
    // mapping matrix shape
    return;
  }
  if (!GSPSInstance.ContentLabel) {
    return;
  }

  let ellipseAngle = NaN;
  if (GSPSInstance.ContentLabel === 'TARGET') {
    ellipseAngle = parseFloat(GSPSInstance['00091025']);
  }

  const _maskShape = GSPSInstance['00091013'];
  const _mappingMatrixShape = GSPSInstance['00091017'];

  let _mappingMatrix;
  if (!_mappingMatrixShape) {
    return;
  }

  let reshapeFunction;
  if (GSPSInstance['00091015'].BulkDataURI) {
    reshapeFunction = reshapeMatrixFromDicomWeb;
    const BulkDataURL = GSPSInstance['00091015'].BulkDataURI;
    const bulkUrl = getBulkUrl(extensionManager, BulkDataURL);
    if (!GSPSInstance['00091015'].retrieveBulkData) {
      await fetchMultipartData(bulkUrl).then(result => {
        _mappingMatrix = result;
      });
    } else {
      GSPSInstance['00091015'].BulkDataURI = bulkUrl;
      _mappingMatrix = await GSPSInstance['00091015'].retrieveBulkData();
    }
  } else if (
    GSPSInstance['00091015'].length > 0 &&
    GSPSInstance['00091015'][0] instanceof ArrayBuffer
  ) {
    _mappingMatrix = GSPSInstance['00091015'][0];
    reshapeFunction = reshapeMatrixFromLocalFile;
  }

  if (!_mappingMatrix) {
    return;
  }

  const mappingMatrixShape = [parseInt(_mappingMatrixShape[0]), parseInt(_mappingMatrixShape[1])];
  const mappingMatrix = reshapeFunction(_mappingMatrix, mappingMatrixShape);

  let _GSPSMaskMatrix;
  if (!_maskShape) {
    return;
  }
  const maskShape = [parseInt(_maskShape[0]), parseInt(_maskShape[1])];
  if (GSPSInstance['00091011'].BulkDataURI) {
    const BulkDataURL = GSPSInstance['00091011'].BulkDataURI;
    const bulkUrl = getBulkUrl(extensionManager, BulkDataURL);
    if (!GSPSInstance['00091011'].retrieveBulkData) {
      await fetchMultipartData(bulkUrl).then(result => {
        _GSPSMaskMatrix = result;
      });
    } else {
      GSPSInstance['00091011'].BulkDataURI = bulkUrl;
      _GSPSMaskMatrix = await GSPSInstance['00091011'].retrieveBulkData();
    }
  } else if (
    GSPSInstance['00091011'].length > 0 &&
    GSPSInstance['00091011'][0] instanceof ArrayBuffer
  ) {
    _GSPSMaskMatrix = GSPSInstance['00091011'][0];
  }

  if (!_GSPSMaskMatrix) {
    return;
  }

  const GSPSMaskMatrix = reshapeFunction(_GSPSMaskMatrix, maskShape);
  // console.log('GSPSMaskMatrixSlice', GSPSMaskMatrix);
  // console.log('GSPSMaskMatrixShape', maskShape);
  // console.log('GSPSMappingMatrix', mappingMatrix);
  // console.log('GSPSMappingMatrixShape', mappingMatrixShape);
  // print shape and type of 'mappingMatrix' ton console
  if (isNaN(ellipseAngle)) {
    return {
      GSPSRecords: convertSourceMappingMatrixToGSPSRecords(mappingMatrix, referencedSOPInstanceUID),
      proccessedGSPSMaskMatrix: GSPSMaskMatrix,
    };
  } else {
    return {
      GSPSRecords: convertTargetMappingMatrixToGSPSRecords(
        mappingMatrix,
        ellipseAngle,
        referencedSOPInstanceUID
      ),
      proccessedGSPSMaskMatrix: GSPSMaskMatrix,
    };
  }
}

export function convertSourceMappingMatrixToGSPSRecords(
  mappingMatrix: number[][],
  referencedSOPInstanceUID: string
): GSPSRecord[] {
  const GSPSRecords: GSPSRecord[] = []; // explicitly typed array
  mappingMatrix.forEach((row, index) => {
    const [x, y, sliceNumber, GraphicGroupID] = row;
    const GSPSRecord: GSPSRecord = {
      GraphicType: GSPSGraphicType.CIRCLE,
      GraphicAnnotationUnits: 'PIXEL',
      GraphicData: [x, y, x, y], // changed to a flat array
      GraphicGroupID: index,
      ReferencedSOPInstanceUID: referencedSOPInstanceUID,
      frameNumber: sliceNumber + 1,
      imageId: undefined,
      worldPos: undefined,
    };
    GSPSRecords.push(GSPSRecord);
  });
  return GSPSRecords;
}

export function convertTargetMappingMatrixToGSPSRecords(
  mappingMatrix: number[][],
  angle: number,
  referencedSOPInstanceUID: string
): GSPSRecord[] {
  const GSPSRecords: GSPSRecord[] = []; // explicitly typed array
  mappingMatrix.forEach((row, index) => {
    // cx:int, cy:int, cwidth:int, cheight:int, sliceNumber:int, GraphicGroupID:int
    const [cx, cy, sliceNumber, cwidth, cheight, GraphicGroupID] = row;
    const GSPSRecord: GSPSRecord = {
      GraphicType: GSPSGraphicType.ELLIPSE,
      GraphicAnnotationUnits: 'PIXEL',
      GraphicData: fourPointsFormat(cx, cy, cwidth, cheight, angle), // changed to a flat array
      GraphicGroupID: index, //GraphicGroupID + 240000000,
      ReferencedSOPInstanceUID: referencedSOPInstanceUID,
      frameNumber: sliceNumber + 1,
      imageId: undefined,
      worldPos: undefined,
    };
    GSPSRecords.push(GSPSRecord);
  });
  return GSPSRecords;
}
