//@ts-ignore
import OHIF, { Types as OhifTypes, PubSubService } from '@ohif/core';
import * as cornerstoneTools from '@cornerstonejs/tools';
import {
  addToolState,
  getToolState,
} from '@cornerstonejs/tools/dist/esm/utilities/stackPrefetch/state';
import DBTGraphicsMetaDataProvider from '../utils/DBTGraphicsMetaDataProvider';
import GSPSImageMetaDataProvider from '../utils/GSPSImageMetaDataProvider';
import indexDBManager from '../utils/indexDBManager';
import { indexedDBName, storeName } from '../Enums';
import { cache } from '@cornerstonejs/core';
import type { Types } from '@cornerstonejs/core';

const { log } = OHIF;
const viewCodeSequence = {
  'cranio-caudal': 'CC',
  'medio-lateral oblique': 'MLO',
};
const EVENTS = {
  IMAGE_LOADED_FROM_CACHE: 'event::GSPSSERVICE:imageloadedfromcache',
};
const MGModality = 'MG';
const DEFAULT_ACTIVE_MODE = 'viewer';
const DEFAULT_MAMMO_MODE = 'mammo';

export default class GSPSService extends PubSubService {
  public static REGISTRATION = {
    name: 'GSPSService',
    create: ({ servicesManager }: OhifTypes.Extensions.ExtensionParams): GSPSService => {
      return new GSPSService(servicesManager);
    },
  };
  listeners: { [key: string]: Function[] };
  EVENTS: { [key: string]: string };
  servicesManager: any;
  countImagesShouldLoad = 0;
  activeImageIds: any = [];
  cachedImages: any = [];
  indexDBManager: any;
  imageLoadedToCache = 0;
  caseCached = false;
  isLoadedDBImages = false;
  activeDataSource;
  loadedViewports = [];
  activeMode = DEFAULT_ACTIVE_MODE;
  stackConfigUpdated = false;
  mammoViewports: any = [];
  constructor(ServicesManager) {
    super(EVENTS);
    this.servicesManager = ServicesManager;
    this.listeners = {};
    this.init();
    this.EVENTS = EVENTS;
  }

  onModeExit() {
    this.destroy();
  }
  public destroy() {
    DBTGraphicsMetaDataProvider.destroy();
    GSPSImageMetaDataProvider.destroy();
    this.activeImageIds = [];
    this.loadedViewports = [];
    this.isLoadedDBImages = false;
    this.activeMode = DEFAULT_ACTIVE_MODE;
    this.mammoViewports = [];
  }
  private async init() {
    // this.indexDBManager = new indexDBManager(indexedDBName, storeName, 1);
    // try {
    //   await this.indexDBManager.init();
    // } catch (error) {
    //   console.error(error);
    // }
  }
  private updateStackPerfetchState(element) {
    const toolState = getToolState(element);
    if (!toolState) {
      return;
    }
    if (toolState.currentImageIdIndex !== 0) {
      addToolState(element, { ...toolState, currentImageIdIndex: 0 });
    }
  }
  public setStackContextPrefetchConfig(element, viewportId) {
    if (
      this.activeMode === DEFAULT_MAMMO_MODE &&
      viewportId &&
      !this.mammoViewports.includes(viewportId)
    ) {
      this.mammoViewports.push(viewportId);
      this.updateStackPerfetchState(element);
    }
    if (this.stackConfigUpdated || !this.activeImageIds) {
      return;
    }

    const maxAfter = this.activeMode === DEFAULT_MAMMO_MODE ? this.activeImageIds.length - 1 : 2;
    const configuration = {
      maxImagesToPrefetch: Infinity,
      // Fetch up to 2 image before and after
      minBefore: 2,
      maxAfter: maxAfter,
      // Increment the cache size by 10 images
      directionExtraImages: 10,
      preserveExistingPool: false,
    };
    cornerstoneTools.utilities.stackContextPrefetch.setConfiguration(configuration);
    this.stackConfigUpdated = true;
  }
  public isLocalDataSource() {
    return this.activeDataSource === 'dicomlocal' || this.activeDataSource === 'dicomjson';
  }
  public setActiveDataSource(dataSource) {
    this.activeDataSource = dataSource;
  }
  public setActiveMode(activeMode) {
    this.activeMode = activeMode;
  }
  public async getCachedMetaData(filter) {
    const cachedMetadata = await this.indexDBManager.get(filter);
    if (!cachedMetadata) {
      return;
    }
    return cachedMetadata;
  }
  public async fetchDBCachedImages(viewportId, imageIds) {
    if (!imageIds || this.isLocalDataSource()) {
      return;
    }
    if (this.loadedViewports.includes(viewportId)) {
      return;
    }
    const promises: any = [];
    for (let i = 0; i < imageIds.length; i++) {
      const imageId = imageIds[i];

      promises.push(
        new Promise<void>(async (resolve, reject) => {
          try {
            const cachedImageObject = await this.indexDBManager.get(imageId);

            if (cachedImageObject && !cache.getImage(imageId)) {
              const imagePixelData = cachedImageObject.getPixelData;
              cachedImageObject.getPixelData = () => imagePixelData;

              const imageLoadObject = this.createImageLoadObject(cachedImageObject);
              cache.putImageLoadObject(imageId, imageLoadObject);
            }

            resolve();
          } catch (error) {
            reject(error);
          }
        })
      );
    }

    // Wait for all promises to complete before continuing
    await Promise.all(promises);
    this.isLoadedDBImages = true;
    this.loadedViewports.push(viewportId);
  }
  private createImageLoadObject(
    imageObject,
    cancelFn?: () => void,
    decache?: () => void
  ): Types.IImageLoadObject {
    const promise = new Promise<Types.IImage>((resolve, reject) => {
      // setTimeout(() => {
      try {
        const image: Types.IImage = imageObject;
        resolve(image);
      } catch (error) {
        reject(error);
      }
      // }, 1000);
    });

    return {
      promise,
      cancelFn,
      decache,
    };
  }
  public setActiveImagesIds(imageIds) {
    if (!imageIds || this.activeImageIds.includes(...imageIds)) {
      return;
    }
    this.activeImageIds = [...this.activeImageIds, ...imageIds];
  }
  public resetActiveImagesIds() {
    this.activeImageIds = [];
  }
  public getActiveImagesIds() {
    return this.activeImageIds;
  }
  public getViewPositionOfInatance(instance) {
    if (instance.Modality !== MGModality) {
      return { letiraly: '', viewPos: '' };
    }
    const { uiNotificationService } = this.servicesManager.services;

    let letiraly;
    if (instance.ImageLaterality) {
      letiraly = instance.ImageLaterality;
    } else if (instance.FrameLaterality) {
      letiraly = instance.FrameLaterality;
    } else if (instance.SeriesDescription) {
      letiraly = instance.SeriesDescription[0];
    } else {
      uiNotificationService.show({
        title: 'Laterality Issue',
        message: 'Cant determine laterality of the breast',
        type: 'error',
        duration: 3000,
      });

      log.error(`Cant determine laterality of the breast ${instance.SOPInstanceUID}`);
    }

    if (!['l', 'r'].includes(letiraly.toLowerCase())) {
      letiraly = null;
      uiNotificationService.show({
        title: 'Laterality Issue',
        message: 'Unrecognized value for breast laterality',
        type: 'error',
        duration: 3000,
      });
      log.error(`Unrecognized value for breast laterality ${instance.SOPInstanceUID}`);
    }

    let viewPos;
    if (instance.ViewCodeSequence) {
      viewPos = viewCodeSequence[instance.ViewCodeSequence[0]?.CodeMeaning];
    } else if (instance.ViewPosition) {
      viewPos = instance.ViewPosition;
    } else if (instance.SeriesDescription) {
      if (instance.SeriesDescription.indexOf('MLO') > -1) {
        viewPos = 'MLO';
      } else if (instance.SeriesDescription.indexOf('CC') > -1) {
        viewPos = 'CC';
      } else if (parseFloat(instance['00189507'][0]['00189510']) > 0) {
        viewPos = 'MLO';
      } else {
        viewPos = 'CC';
      }
    } else {
      letiraly = null;
      uiNotificationService.show({
        title: ' View Position Issue',
        message: 'Cant determine View Position',
        type: 'error',
        duration: 3000,
      });
      log.error(`Cant determine View Position ${instance.SOPInstanceUID}`);
    }
    return { letiraly, viewPos };
  }
}
