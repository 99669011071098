import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
//@ts-ignore
import { ErrorBoundary } from '@ohif/ui';
//@ts-ignore
import { useAppConfig } from '@state';
import Header from '../ui/Header';
import LoadFilesButton from './LoadFilesButton';
import './header.css';
function ViewerHeader({ hotkeysManager, extensionManager, servicesManager }) {
  const [appConfig] = useAppConfig();
  const navigate = useNavigate();
  const location = useLocation();
  const { GSPSService } = servicesManager.services;
  const renderLoadButton = GSPSService.isLocalDataSource() ? true : false;
  const onClickReturnButton = () => {
    const { pathname } = location;
    const dataSourceIdx = pathname.indexOf('/', 1);
    const query = new URLSearchParams(window.location.search);
    const configUrl = query.get('configUrl');
    const dataSourceName = pathname.substring(dataSourceIdx + 1);
    const existingDataSource = extensionManager.getDataSources(dataSourceName);

    const searchQuery = new URLSearchParams();
    if (dataSourceIdx !== -1 && existingDataSource) {
      searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
    }

    if (configUrl) {
      searchQuery.append('configUrl', configUrl);
    }
    if (pathname.substring(dataSourceIdx + 1) === 'dicomlocal') {
      navigate({
        pathname: '/local',
        search: '',
      });
    } else {
      navigate({
        pathname: '/',
        search: decodeURIComponent(searchQuery.toString()),
      });
    }
  };

  const { t } = useTranslation();
  const menuOptions = [];
  return (
    <Header
      menuOptions={menuOptions}
      isReturnEnabled={!!appConfig.showStudyList}
      onClickReturnButton={onClickReturnButton}
      isSticky={false}
    >
      <ErrorBoundary context="Primary Toolbar">
        <div className="relative flex justify-center">
          {renderLoadButton && (
            <LoadFilesButton servicesManager={servicesManager} extensionManager={extensionManager} />
          )}
        </div>
      </ErrorBoundary>
    </Header>
  );
}

export default ViewerHeader;
