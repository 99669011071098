import { GSPSRecord } from '../Enums/displaySet';

interface GSPSImageMetaDataProvider {
    GSPSRecords: {
        target: GSPSRecord[],
        source: GSPSRecord[],
    }
};

const state: Record<string, GSPSImageMetaDataProvider> = {};

const metadataProvider = {
    add: (imageId: string, payload: GSPSImageMetaDataProvider): void => {
        state[imageId] = payload;
    },
    get: (type: string, imageId: string): GSPSImageMetaDataProvider | undefined => {
        if (type === 'GSPS3DImagePoints') {
            return state[imageId];
        }
    },
    destroy: () => {
        for (const key in state) {
            if (Object.hasOwnProperty.call(state, key)) {
                delete state[key];
            }
        }
    },
};
export default metadataProvider;
