import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
//@ts-ignore
import { useLocation } from 'react-router';
import { ErrorBoundary } from '@ohif/ui';
//@ts-ignore
import { ServicesManager, HangingProtocolService, CommandsManager, DicomMetadataStore } from '@ohif/core';
//@ts-ignore
import { useAppConfig } from '@state';
import ViewerHeader from './ViewerHeader';
import SidePanelWithServices from '@ohif/extension-default/src/Components/SidePanelWithServices';
import { eventTarget, Enums } from '@cornerstonejs/core';
import { LoadingIndicatorProgress } from '../ui';

function ViewerLayout({
  // From Extension Module Params
  extensionManager,
  servicesManager,
  hotkeysManager,
  commandsManager,
  // From Modes
  viewports,
  ViewportGridComp,
  leftPanels = [],
  rightPanels = [],
  leftPanelDefaultClosed = false,
  rightPanelDefaultClosed = false,
}) {
  const [appConfig] = useAppConfig();
  const dataSources = extensionManager.getDataSources();
  const dataSource = dataSources[0];

  const { hangingProtocolService, displaySetService, GSPSService } = servicesManager.services;
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(appConfig.showLoadingIndicator);
  const [loadingPercent, setLoadingPercent] = useState({ success: 0, fails: 0, loaded: 0 });
  const location = useLocation();
  const { pathname } = location;
  const dataSourceIdx = pathname.indexOf('/', 1);
  /**
   * Set body classes (tailwindcss) that don't allow vertical
   * or horizontal overflow (no scrolling). Also guarantee window
   * is sized to our viewport.
   */
  useEffect(() => {
    document.body.classList.add('bg-black');
    document.body.classList.add('overflow-hidden');
    GSPSService.setActiveDataSource(pathname.substring(dataSourceIdx + 1));
    return () => {
      document.body.classList.remove('bg-black');
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  const getComponent = id => {
    const entry = extensionManager.getModuleEntry(id);

    if (!entry) {
      throw new Error(
        `${id} is not valid for an extension module. Please verify your configuration or ensure that the extension is properly registered. It's also possible that your mode is utilizing a module from an extension that hasn't been included in its dependencies (add the extension to the "extensionDependencies" array in your mode's index.js file)`
      );
    }

    let content;
    if (entry && entry.component) {
      content = entry.component;
    } else {
      throw new Error(
        `No component found from extension ${id}. Check the reference string to the extension in your Mode configuration`
      );
    }

    return { entry, content };
  };

  const getPanelData = id => {
    const { content, entry } = getComponent(id);

    return {
      id: entry.id,
      iconName: entry.iconName,
      iconLabel: entry.iconLabel,
      label: entry.label,
      name: entry.name,
      content,
    };
  };
  // 
  useEffect(() => {
    if (GSPSService.isLocalDataSource())
      return;
    let loadedImages = 0;
    const processLoadingProgressBar = (evt) => {
      const activeImageIds = GSPSService.getActiveImagesIds();
      if (!showLoadingIndicator)
        return;
      loadedImages++;
      setLoadingPercent(prevState => {
        return { ...prevState, success: (loadedImages / (activeImageIds.length === 0 ? 1 : activeImageIds.length) * 100), loaded: prevState.loaded + 1 };
      });
      if (loadedImages === activeImageIds.length) {
        setShowLoadingIndicator(false);
      }
    };
    eventTarget.addEventListener(Enums.Events.IMAGE_CACHE_IMAGE_ADDED, processLoadingProgressBar);
  }, []);

  useEffect(() => {
    const { unsubscribe } = hangingProtocolService.subscribe(
      HangingProtocolService.EVENTS.PROTOCOL_CHANGED,

      // Todo: right now to set the loading indicator to false, we need to wait for the
      // hangingProtocolService to finish applying the viewport matching to each viewport,
      // however, this might not be the only approach to set the loading indicator to false. we need to explore this further.
      ({ viewportMatchDetails }) => {
        GSPSService.resetActiveImagesIds();
        for (const [_, details] of viewportMatchDetails.entries()) {
          if (details) {
            const { displaySetInstanceUID } = details.displaySetsInfo[0];
            if (displaySetInstanceUID) {
              const displayset = displaySetService.getDisplaySetByUID(displaySetInstanceUID);
              const imageIds = dataSource.getImageIdsForDisplaySet(displayset);
              GSPSService.setActiveImagesIds(imageIds);
            }
          }
        }
        if (GSPSService.isLocalDataSource())
          setShowLoadingIndicator(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [hangingProtocolService]);

  const getViewportComponentData = viewportComponent => {
    const { entry } = getComponent(viewportComponent.namespace);

    return {
      component: entry.component,
      displaySetsToDisplay: viewportComponent.displaySetsToDisplay,
    };
  };

  const leftPanelComponents = leftPanels.map(getPanelData);
  const rightPanelComponents = rightPanels.map(getPanelData);
  const viewportComponents = viewports.map(getViewportComponentData);

  return (
    <div>
      <ViewerHeader
        hotkeysManager={hotkeysManager}
        extensionManager={extensionManager}
        servicesManager={servicesManager}
      />
      <div
        className="relative flex w-full flex-row flex-nowrap items-stretch overflow-hidden bg-black"
        style={{ height: 'calc(100vh - 52px' }}
      >
        <React.Fragment>
          {showLoadingIndicator &&
            <LoadingIndicatorProgress setShowLoadingIndicator={setShowLoadingIndicator} numFails={loadingPercent.fails} percentComplete={loadingPercent.success} />
          }
          {/* LEFT SIDEPANELS */}
          {leftPanelComponents.length ? (
            <ErrorBoundary context="Left Panel">
              <SidePanelWithServices
                side="left"
                activeTabIndex={leftPanelDefaultClosed ? null : 0}
                tabs={leftPanelComponents}
                servicesManager={servicesManager}
              />
            </ErrorBoundary>
          ) : null}
          {/* TOOLBAR + GRID */}
          <div className="flex h-full flex-1 flex-col">
            <div className="relative flex h-full flex-1 items-center justify-center overflow-hidden bg-black">
              <ErrorBoundary context="Grid">
                <ViewportGridComp
                  servicesManager={servicesManager}
                  viewportComponents={viewportComponents}
                  commandsManager={commandsManager}
                />
              </ErrorBoundary>
            </div>
          </div>
        </React.Fragment>
      </div>

    </div>
  );
}

ViewerLayout.propTypes = {
  extensionManager: PropTypes.shape({
    getModuleEntry: PropTypes.func.isRequired,
  }).isRequired,
  commandsManager: PropTypes.instanceOf(CommandsManager),
  servicesManager: PropTypes.instanceOf(ServicesManager),
  leftPanels: PropTypes.array,
  rightPanels: PropTypes.array,
  leftPanelDefaultClosed: PropTypes.bool.isRequired,
  rightPanelDefaultClosed: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  viewports: PropTypes.array,
};

export default ViewerLayout;
