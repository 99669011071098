import React, { useCallback } from 'react';
import { ProgressLoadingBar, Icon } from '@ohif/ui';

export default function LoadingIndicatorProgress({ setShowLoadingIndicator, numFails = 0, percentComplete }: {
    numFails: any,
    percentComplete: number,
    setShowLoadingIndicator: any,
}) {
    const getPercentCompleteRounded = useCallback(
        () => Math.min(100, Math.round(percentComplete)),
        [percentComplete]
    );

    const getViewLoadInBackgroundComponent = () => {
        return (
            <div className="ml-2 flex w-6 justify-center">
                {percentComplete > 4 && (
                    <div onClick={() => { setShowLoadingIndicator(false); }}>
                        <Icon
                            className="cursor-pointer"
                            name="eye-visible"
                            width="24"
                            height="24"
                        ></Icon>
                    </div>
                )}
            </div>
        );
    };
    return (
        <>
            <div
                className="h-full w-full absolute top-0 left-0 z-50 flex  items-center justify-center space-y-5 text-blue-300 bg-black"
            >
                <div className="w-48">

                    <ProgressLoadingBar
                        progress={percentComplete === 0 ? undefined : Math.min(100, percentComplete)}
                    ></ProgressLoadingBar>
                </div>
                <div className="flex w-24 items-center" style={{ marginTop: '0px' }}>
                    <div className="w-10 text-right">{`${getPercentCompleteRounded()}%`}</div>
                    {getViewLoadInBackgroundComponent()}

                </div>
            </div>

        </>

    );
}