import { utilities } from '@cornerstonejs/core';

import type { Types } from '@cornerstonejs/core';
import DBTGraphicsMetaDataProvider from './DBTGraphicsMetaDataProvider';
import { GSPSGraphicType, GSPSRecord } from '../Enums/displaySet';
import processBreastMaskMatrix from './processBreastMaskMatrix';
import processBreastMaskMatrixAndMappingMatrix from './processBreastMaskMatrixAndMappingMatrix';

function compare2DArrays(arr1, arr2) {
  // Check if arrays have the same number of rows
  if (arr1.length !== arr2.length) {
    return { areEqual: false, differences: ['Arrays have different number of rows'] };
  }

  const differences = [];
  let areEqual = true;

  for (let i = 0; i < arr1.length; i++) {
    // Check if rows have the same number of columns
    if (arr1[i].length !== arr2[i].length) {
      areEqual = false;
      differences.push(`Row ${i} has different number of columns`);
      continue; // Skip further comparison of this row
    }

    for (let j = 0; j < arr1[i].length; j++) {
      if (arr1[i][j] !== arr2[i][j]) {
        areEqual = false;
        differences.push(
          `Different element at [${i}][${j}]: arr1=${arr1[i][j]}, arr2=${arr2[i][j]}`
        );
      }
    }
  }

  return { areEqual, differences };
}

export default async function loadGSPSData(extensionManager, displaySet) {
  const { GraphicAnnotationSequence, ReferencedSeriesSequence } = displaySet.instance; // start here for refactoring
  // let GSPSRecords;
  // let proccessedGSPSMaskMatrix;
  // if (GraphicAnnotationSequence == null || GraphicAnnotationSequence.length === 0) {
  //   console.log('PATH', 'took the fast route');
  //   ({ GSPSRecords, proccessedGSPSMaskMatrix } = await processBreastMaskMatrixAndMappingMatrix(
  //     extensionManager,
  //     displaySet.instance
  //   ));
  // } else {
  //   // print first 3 elements of GraphicAnnotationSequence
  //   console.log('PATH', 'took the slow route');
  //   GSPSRecords = processGSPSData(GraphicAnnotationSequence);
  //   proccessedGSPSMaskMatrix = await processBreastMaskMatrix(extensionManager, displaySet.instance);
  // }

  // console.log('GSPSRecords', GSPSRecords.slice(0, 5));
  // console.log('GSPSFULL', GSPSRecords);
  // console.log('proccessedGSPSMaskMatrix', proccessedGSPSMaskMatrix);
  // console.log('GSPSRecords2', GSPSRecords2.slice(0, 5));
  // console.log('GSPSFULL2', GSPSRecords2);
  // console.log('proccessedGSPSMaskMatrix2', proccessedGSPSMaskMatrix2);

  //const result = compare2DArrays(proccessedGSPSMaskMatrix, proccessedGSPSMaskMatrix2);
  //console.log(result.areEqual); // Output: false
  //console.log(result.differences); // Output: ["Different element at [1][1]: arr1=5, arr2=8"]

  const { GSPSRecords, proccessedGSPSMaskMatrix } = await processBreastMaskMatrixAndMappingMatrix(
    extensionManager,
    displaySet.instance
  );
  registerGSPSSeriesData(ReferencedSeriesSequence, GSPSRecords, proccessedGSPSMaskMatrix);
}
export function registerGSPSSeriesData(ReferencedSeriesSequence, GSPSRecords, GSPSMaskMatrix) {
  const targetGSPSRecords = GSPSRecords.filter(
    record => record.GraphicType === GSPSGraphicType.ELLIPSE
  );
  const sourceGSPSRecords = GSPSRecords.filter(
    record => record.GraphicType === GSPSGraphicType.CIRCLE
  );
  if (ReferencedSeriesSequence) {
    const { SeriesInstanceUID } = ReferencedSeriesSequence;
    if (SeriesInstanceUID) {
      const seriesGSPSRecords = DBTGraphicsMetaDataProvider.get(
        'displaysetImagesProvider',
        SeriesInstanceUID
      );
      if (seriesGSPSRecords && seriesGSPSRecords.GSPSRecords) {
        DBTGraphicsMetaDataProvider.remove('displaysetImagesProvider', SeriesInstanceUID);
        const _targetGSPSRecords =
          seriesGSPSRecords.GSPSRecords.target.length > 0 && targetGSPSRecords.length === 0
            ? seriesGSPSRecords.GSPSRecords.target
            : targetGSPSRecords;
        const _sourceGSPSRecords =
          seriesGSPSRecords.GSPSRecords.source.length > 0 && sourceGSPSRecords.length === 0
            ? seriesGSPSRecords.GSPSRecords.source
            : sourceGSPSRecords;
        DBTGraphicsMetaDataProvider.add(SeriesInstanceUID, {
          GSPSRecords: {
            target: _targetGSPSRecords,
            source: _sourceGSPSRecords,
          },
          GSPSMaskMatrix: GSPSMaskMatrix,
        });
      } else {
        DBTGraphicsMetaDataProvider.add(SeriesInstanceUID, {
          GSPSRecords: {
            target: targetGSPSRecords,
            source: sourceGSPSRecords,
          },
          GSPSMaskMatrix: GSPSMaskMatrix,
        });
      }
    }
  }
}
export function processGSPSData(GraphicAnnotationSequence) {
  const GSPSRecords: GSPSRecord[] = [];
  GraphicAnnotationSequence.forEach(gAnnotation => {
    const { GraphicObjectSequence, ReferencedImageSequence } = gAnnotation;
    if (GraphicObjectSequence) {
      GraphicObjectSequence.forEach(graphicItem => {
        const { GraphicAnnotationUnits, GraphicType, GraphicData, GraphicGroupID } = graphicItem;
        const frameNo =
          ReferencedImageSequence && ReferencedImageSequence.length === 1
            ? ReferencedImageSequence[0].ReferencedFrameNumber
            : undefined;
        const GSPSRecord: GSPSRecord = {
          GraphicType: GraphicType,
          GraphicAnnotationUnits: GraphicAnnotationUnits,
          GraphicData: GraphicData,
          GraphicGroupID: GraphicGroupID,
          imageId: undefined,
          ReferencedSOPInstanceUID:
            ReferencedImageSequence && ReferencedImageSequence.length === 1
              ? ReferencedImageSequence[0].ReferencedSOPInstanceUID
              : undefined,
          frameNumber: frameNo,
          worldPos: undefined,
        };
        GSPSRecords.push(GSPSRecord);
      });
    }
  });

  // Sort GSPSRecords by GraphicGroupID in increasing order
  // GSPSRecords.sort((a, b) => a.GraphicGroupID - b.GraphicGroupID);
  return GSPSRecords;
}

export function GSPSRecordReferencesSOPInstanceUID(GSPSRecord, SOPInstanceUID, frameNumber) {
  const { ReferencedSOPInstanceUID } = GSPSRecord;
  const ReferencedFrameNumber = GSPSRecord.frameNumber || 1;
  if (frameNumber && Number(frameNumber) !== Number(ReferencedFrameNumber)) {
    return false;
  }
  if (ReferencedSOPInstanceUID === SOPInstanceUID) {
    return true;
  }
}

export function processGSPSRecord(GSPSRecord, imageId) {
  const { GraphicType, GraphicData } = GSPSRecord;
  let renderableData: Types.Point3[] | undefined = [];
  switch (GraphicType) {
    case GSPSGraphicType.POINT:
    case GSPSGraphicType.MULTIPOINT:
    case GSPSGraphicType.POLYLINE:
      renderableData = [];

      for (let i = 0; i < GraphicData.length; i += 2) {
        const worldPos = utilities.imageToWorldCoords(imageId, [
          GraphicData[i],
          GraphicData[i + 1],
        ]);
        renderableData.push(worldPos ? worldPos : [0, 0, 0]); // TODO: Make this [-1 -1 -1]? and then don't show the ellipse / or try making this None
      }

      break;
    case GSPSGraphicType.CIRCLE: {
      const pointsWorld: any = [];
      const avg01 = GraphicData[0]; //(GraphicData[0] + GraphicData[2]) / 2; // TODO: Optimise this code; it's unnecessary and a poor feature of
      const avg23 = GraphicData[1]; //(GraphicData[1] + GraphicData[3]) / 2;
      const worldPos = utilities.imageToWorldCoords(imageId, [avg01, avg23]);

      pointsWorld.push(worldPos);

      renderableData = [pointsWorld[0]];

      break;
    }
    default:
      console.warn('Unsupported GraphicType:', GraphicType);
  }
  GSPSRecord.worldPos = renderableData;
}
