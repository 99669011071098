const mvpProtocol = {
  id: 'mammo',
  locked: true,
  hasUpdatedPriorsInformation: false,
  name: 'Mammo Viewer',
  createdDate: '2024-03-25T08:22:08.894Z',
  modifiedDate: '2024-03-25T08:22:08.894Z',
  availableTo: {},
  editableBy: {},
  protocolMatchingRules: [],
  displaySetSelectors: {
    RCC: {
      seriesMatchingRules: [
        {
          attribute: '_viewPos',
          weight: 5,
          constraint: {
            contains: {
              value: 'CC',
            },
          },
          required: true,
        },
        {
          attribute: '_letiraly',
          weight: 5,
          constraint: {
            contains: {
              value: 'R',
            },
          },
          required: true,
        },
      ],
    },
    LCC: {
      seriesMatchingRules: [
        {
          attribute: '_viewPos',
          weight: 5,
          constraint: {
            contains: {
              value: 'CC',
            },
          },
          required: true,
        },
        {
          attribute: '_letiraly',
          weight: 5,
          constraint: {
            contains: {
              value: 'L',
            },
          },
          required: true,
        },
      ],
    },
    RMLO: {
      seriesMatchingRules: [
        {
          attribute: '_viewPos',
          weight: 5,
          constraint: {
            contains: {
              value: 'MLO',
            },
          },
          required: true,
        },
        {
          attribute: '_letiraly',
          weight: 5,
          constraint: {
            contains: {
              value: 'R',
            },
          },
          required: true,
        },
      ],
    },
    LMLO: {
      seriesMatchingRules: [
        {
          attribute: '_viewPos',
          weight: 5,
          constraint: {
            contains: {
              value: 'MLO',
            },
          },
          required: true,
        },
        {
          attribute: '_letiraly',
          weight: 5,
          constraint: {
            contains: {
              value: 'L',
            },
          },
          required: true,
        },
      ],
    },
  },
  toolGroupIds: ['default'],
  stages: [
    {
      id: 'mvp-4X1staging',
      name: 'mvp staging',
      stageActivation: {
        enabled: {
          minViewportsMatched: 4,
        },
      },
      viewportStructure: {
        type: 'grid',
        properties: {
          rows: 1,
          columns: 4,
        },
      },
      viewports: [
        {
          viewportOptions: {
            viewportId: 'mvp-rcc-viewport',
            toolGroupId: 'GSPSToolGroup',
            viewportType: 'stack',
          },
          displaySets: [{ id: 'RCC' }],
          syncGroups: [],
        },
        {
          viewportOptions: {
            viewportId: 'mvp-rmlo-viewport',
            toolGroupId: 'GSPSToolGroup',
            viewportType: 'stack',
          },
          displaySets: [{ id: 'RMLO' }],
          syncGroups: [],
        },
        {
          viewportOptions: {
            viewportId: 'mvp-lcc-viewport',
            toolGroupId: 'GSPSToolGroup',
            viewportType: 'stack',
          },
          displaySets: [{ id: 'LCC' }],
          syncGroups: [],
        },
        {
          viewportOptions: {
            viewportId: 'mvp-lmlo-viewport',
            toolGroupId: 'GSPSToolGroup',
            viewportType: 'stack',
          },
          displaySets: [{ id: 'LMLO' }],
          syncGroups: [],
        },
      ],
      createdDate: '2024-03-25T08:22:08.894Z',
    },
    {
      id: 'mvp-2XR1staging',
      name: 'mvp staging',
      stageActivation: {
        enabled: {
          minViewportsMatched: 2,
        },
      },
      viewportStructure: {
        type: 'grid',
        properties: {
          rows: 1,
          columns: 2,
        },
      },
      viewports: [
        {
          viewportOptions: {
            viewportId: 'mvp-cc-viewport',
            toolGroupId: 'GSPSToolGroup',
            viewportType: 'stack',
          },
          displaySets: [{ id: 'RCC' }],
          syncGroups: [],
        },

        {
          viewportOptions: {
            viewportId: 'mvp-mlo-viewport',
            toolGroupId: 'GSPSToolGroup',
            viewportType: 'stack',
          },
          displaySets: [{ id: 'RMLO' }],
          syncGroups: [],
        },
      ],
      createdDate: '2024-03-25T08:22:08.894Z',
    },
    {
      id: 'mvp-2XL1staging',
      name: 'mvp staging',
      stageActivation: {
        enabled: {
          minViewportsMatched: 2,
        },
      },
      viewportStructure: {
        type: 'grid',
        properties: {
          rows: 1,
          columns: 2,
        },
      },
      viewports: [
        {
          viewportOptions: {
            viewportId: 'mvp-cc-viewport',
            toolGroupId: 'GSPSToolGroup',
            viewportType: 'stack',
          },
          displaySets: [{ id: 'LCC' }],
          syncGroups: [],
        },

        {
          viewportOptions: {
            viewportId: 'mvp-mlo-viewport',
            toolGroupId: 'GSPSToolGroup',
            viewportType: 'stack',
          },
          displaySets: [{ id: 'LMLO' }],
          syncGroups: [],
        },
      ],
      createdDate: '2024-03-25T08:22:08.894Z',
    },
  ],
  numberOfPriorsReferenced: 2,
};

function getHangingProtocolModule() {
  return [
    {
      name: mvpProtocol.id,
      id: mvpProtocol.id,
      protocol: mvpProtocol,
    },
  ];
}

export default getHangingProtocolModule;
